import { Roles } from './roles';

export class Users {
    private _id: number;
    private _fullName: string;
    private _username: string;
    private _password: string;
    private _role: string;


    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter fullName
     * @return {string}
     */
	public get fullName(): string {
		return this._fullName;
	}

    /**
     * Getter username
     * @return {string}
     */
	public get username(): string {
		return this._username;
	}

    /**
     * Getter password
     * @return {string}
     */
	public get password(): string {
		return this._password;
	}

    /**
     * Getter role
     * @return {string}
     */
	public get role(): string {
		return this._role;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter fullName
     * @param {string} value
     */
	public set fullName(value: string) {
		this._fullName = value;
	}

    /**
     * Setter username
     * @param {string} value
     */
	public set username(value: string) {
		this._username = value;
	}

    /**
     * Setter password
     * @param {string} value
     */
	public set password(value: string) {
		this._password = value;
	}

    /**
     * Setter role
     * @param {string} value
     */
	public set role(value: string) {
		this._role = value;
	}

}
