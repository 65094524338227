import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Reference } from 'src/app/shared/models/reference';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FormControl } from '@angular/forms';
import { Brand } from 'src/app/shared/models/brand';

interface DialogData{
  type: string,
  ref?: Reference
}

@Component({
  selector: 'app-add-reference',
  templateUrl: './add-reference.component.html',
  styleUrls: ['./add-reference.component.css']
})
export class AddReferenceComponent implements OnInit {

  public dialogTitle: string

  public allBrands: Brand[];
  public onLoad: boolean = false;

  public selectedBrand: Brand;
  public input_refName_value: FormControl = new FormControl();
  public input_refContenance_value: FormControl = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<AddReferenceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _functionsService: FunctionsService,
    private _apiService: ApiService,
  ) {
    switch(data.type){
      case 'add':
        this.dialogTitle = "Ajouter";
        break;
      case 'edit':
        this.dialogTitle = "Modifier";
        break;
    }
    this.dialogTitle += " une référence";
  }

  ngOnInit(): void {
    this._apiService.getAllBrands().subscribe((allBrands: Brand[])=>{
      this.allBrands = allBrands;
    });
  }

  public btn_valid_click(): void{
    if(this.selectedBrand == (undefined || null)){
      this._functionsService.openErrorSnackBar("Aucune marque sélectionnée")
    }else if(this.input_refName_value.value == (null || "")){
      this._functionsService.openErrorSnackBar("Saisir un nom de marque");
    }else if(this.input_refContenance_value.value == (null)){
      this._functionsService.openErrorSnackBar("Saisir une contenance");
    }else{
      switch(this.data.type){
        case 'add':
          let theRef: Reference = new Reference();
          theRef.libelle = this.input_refName_value.value;
          theRef.brand = this.selectedBrand.id;
          theRef.contenance = this.input_refContenance_value.value;
          this.onLoad = true;
          this._apiService.postReference(theRef).subscribe((result: boolean)=>{
            if(result){
              this.dialogRef.close(result);
            }
            this.onLoad = false;
          },
          ()=>{this.onLoad = false;},
          ()=>{this.onLoad = false;});
          break;
        case 'edit':
          this.data.ref.libelle = this.input_refName_value.value;
          //put & close
          break;
      }

    }
  }

}
