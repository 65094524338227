<h1 *ngIf="data.type == 'fill'" mat-dialog-title>Ajouter du stock</h1>
<h1 *ngIf="data.type == 'transfer'" mat-dialog-title>Transférer du stock</h1>
<mat-dialog-content>
    <div *ngIf="onLoad" class="py-3 d-flex justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!onLoad">
        <form class="text-center">
            <div *ngIf="data.type == 'transfer'">À transférer</div>
            <div class="selectList d-flex flex-row justify-content-center">
                <div class="selectListLoad d-flex align-items-center">
                    <mat-spinner *ngIf="locationLoad" [diameter]="20"></mat-spinner>
                </div>
                <mat-form-field>
                    <mat-label>Lieu</mat-label>
                    <mat-select [(value)]="selectedLocation" (selectionChange)="select_location_change()">
                        <mat-option *ngFor="let someLocation of allLocations" [value]="someLocation">
                            {{ someLocation.libelle }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngFor="let subLocation of sublocations, index as i">
                <mat-form-field>
                    <mat-label>Sous-lieu</mat-label>
                    <mat-select [(value)]="subLocation.selectedLocation" (selectionChange)="select_location_change(i)">
                        <mat-option *ngFor="let someLocation of subLocation.locations" [value]="someLocation">
                            {{ someLocation.libelle }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="selectList d-flex flex-row justify-content-center">
                <div class="selectListLoad d-flex align-items-center">
                    <mat-spinner *ngIf="brandLoad" [diameter]="20"></mat-spinner>
                </div>
                <mat-form-field>
                    <mat-label>Marque</mat-label>
                    <mat-select [(value)]="selectedBrand" (selectionChange)="select_brand_change()">
                        <mat-option *ngFor="let someBrand of allBrands" [value]="someBrand">
                            {{ someBrand.libelle }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="selectList d-flex flex-row justify-content-center">
                <div class="selectListLoad d-flex align-items-center">
                    <mat-spinner *ngIf="referenceLoad" [diameter]="20"></mat-spinner>
                </div>
                <mat-form-field>
                    <mat-label>Référence</mat-label>
                    <mat-select [(value)]="selectedReference" (selectionChange)="select_reference_change()" [disabled]="allReferences == undefined ? true : false">
                        <mat-option *ngFor="let someRef of allReferences" [value]="someRef">
                            {{ someRef.libelle }} - {{someRef.contenance}}cl
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>Quantité</mat-label>
                    <input matInput type="number" [formControl]="quantite">
                </mat-form-field>
            </div>
            <span *ngIf="data.type == 'transfer'">
                <div>Où transférer</div>
                <div class="selectList d-flex flex-row justify-content-center">
                    <div class="selectListLoad d-flex align-items-center">
                        <mat-spinner *ngIf="locationTransferLoad" [diameter]="20"></mat-spinner>
                    </div>
                    <mat-form-field>
                        <mat-label>Lieu</mat-label>
                        <mat-select [(value)]="selectedLocationTransfer" (selectionChange)="select_locationTransfer_change()">
                            <mat-option *ngFor="let someLocation of allLocations" [value]="someLocation">
                                {{ someLocation.libelle }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngFor="let subLocation of sublocationsTransfer, index as i">
                    <mat-form-field>
                        <mat-label>Sous-lieu</mat-label>
                        <mat-select [(value)]="subLocation.selectedLocation" (selectionChange)="select_locationTransfer_change(i)">
                            <mat-option *ngFor="let someLocation of subLocation.locations" [value]="someLocation">
                                {{ someLocation.libelle }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </span>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button *ngIf="data.type == 'fill'" mat-raised-button (click)="btn_validFill_click()">Ajouter</button>
    <button *ngIf="data.type == 'transfer'" mat-raised-button (click)="btn_validTransfer_click()">Transférer</button>
</mat-dialog-actions>