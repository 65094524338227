import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {HttpClientModule} from '@angular/common/http';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatProgressSpinnerModule, MatSpinner} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressBarModule, MatProgressBar} from '@angular/material/progress-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './view/home/home.component';
import { ButtonComponent } from './shared/components/button/button.component';
import { LoginComponent } from './shared/components/login/login.component';
import { LocationSelectionComponent } from './view/home/location-selection/location-selection.component';
import { StockComponent } from './view/home/stock/stock.component';
import { UserConnectedInfoComponent } from './shared/components/user-connected-info/user-connected-info.component';
import { StockDrinkDialogComponent } from './view/home/stock/stock-drink-dialog/stock-drink-dialog.component';
import { AddStockComponent } from './view/home/stock/add-stock/add-stock.component';
import { SettingsComponent } from './view/home/settings/settings.component';
import { BrandsComponent } from './view/home/settings/brands/brands.component';
import { ReferencesComponent } from './view/home/settings/references/references.component';
import { LocationsComponent } from './view/home/settings/locations/locations.component';
import { AddBrandComponent } from './view/home/settings/brands/add-brand/add-brand.component';
import { AddLocationComponent } from './view/home/settings/locations/add-location/add-location.component';
import { AddReferenceComponent } from './view/home/settings/references/add-reference/add-reference.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ButtonComponent,
    LoginComponent,
    LocationSelectionComponent,
    StockComponent,
    UserConnectedInfoComponent,
    StockDrinkDialogComponent,
    AddStockComponent,
    SettingsComponent,
    BrandsComponent,
    ReferencesComponent,
    LocationsComponent,
    AddBrandComponent,
    AddLocationComponent,
    AddReferenceComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatProgressBarModule,
    MatSnackBarModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
