<div class="top-right-info">
    <span class="px-2 clickable">
        <i class="fas fa-user"></i>
        {{ loggedUser.fullName }}
    </span>
    <span *ngIf="loggedUser.role == 'a'">
        <span class="separator"></span>
        <span class="px-2 clickable">
            <i class="fas fa-crown"></i>
        </span>
    </span>
    <span class="separator"></span>
    <span class="px-2 clickable" (click)="btn_settings_click()">
        <i class="fas fa-cog"></i>
    </span>
</div>
