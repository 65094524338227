import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from 'src/app/shared/models/location';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/shared/services/api.service';
import { FunctionsService } from 'src/app/shared/services/functions.service';

interface DialogData{
  type: string,
  location?: Location
}

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.css']
})
export class AddLocationComponent implements OnInit {
  public dialogTitle: string;

  public input_locationName_value: FormControl = new FormControl();
  public subLocationSelected: Location;

  public allLocations: Location[];
  public onLoad: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _functionsService: FunctionsService,
    private _apiService: ApiService,
  ) {
    switch(data.type){
      case 'add':
        this.dialogTitle = "Ajouter";
        break;
      case 'edit':
        this.dialogTitle = "Modifier";
        break;
    }
    this.dialogTitle += " un lieu";
  }

  ngOnInit(): void {
    this.refreshAllLocations();
  }

  private refreshAllLocations(): void{
    this._apiService.getAllLocations().subscribe((allLocationsFromApi: Location[])=>{
      this.allLocations = allLocationsFromApi;
    });
  }
  
  public btn_valid_click(): void{
    if(this.input_locationName_value.value == (null || "")){
      this._functionsService.openErrorSnackBar("Saisir un nom de lieu");
    }else{
      switch(this.data.type){
        case 'add':
          let theLocation: Location = new Location();
          theLocation.libelle = this.input_locationName_value.value;
          if(this.subLocationSelected != (null && undefined)){
            theLocation.sublocation = this.subLocationSelected.id;
          }else{
            theLocation.sublocation = null;
          }
          this.onLoad = true;
          this._apiService.postLocation(theLocation).subscribe((result: boolean)=>{
            if(result){
              this.dialogRef.close(result);
            }
            this.onLoad = false;
          });
          break;
        case 'edit':
          this.data.location.libelle = this.input_locationName_value.value;
          //put & close
          break;
      }

    }
  }

}
