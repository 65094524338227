<div *ngIf="selectedSetting == 'home'">
    <span class="backHome px-2 py-2" (click)="btn_backToHome_click()">
        <i class="fas fa-chevron-left"></i> &nbsp; <i class="fas fa-home"></i>
    </span>
    <div class="pageContent d-flex justify-content-center align-items-center flex-column">
        <button mat-raised-button class="my-3" (click)="btn_brands_click()">Marques</button>
        <button mat-raised-button class="my-3" (click)="btn_references_click()">Références</button>
        <button mat-raised-button class="my-3" (click)="btn_locations_click()">Lieux</button>
        <button mat-raised-button color="warn" class="my-3" (click)="btn_disconnect_click()">Déconnexion</button>
    </div>
</div>
<div *ngIf="selectedSetting != 'home'">
    <span class="backHome px-2 py-2" (click)="btn_backToSettings_click()">
        <i class="fas fa-chevron-left"></i> &nbsp; <i class="fas fa-cog"></i>
    </span>
    <div *ngIf="selectedSetting == 'brands'">
        <app-brands></app-brands>
    </div>
    <div *ngIf="selectedSetting == 'references'">
        <app-references></app-references>
    </div>
    <div *ngIf="selectedSetting == 'locations'">
        <app-locations></app-locations>
    </div>
</div>
