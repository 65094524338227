import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Reference } from 'src/app/shared/models/reference';
import { Location } from 'src/app/shared/models/location';
import { Brand } from 'src/app/shared/models/brand';
import { Stock } from 'src/app/shared/models/stock';
import { ApiService } from 'src/app/shared/services/api.service';

interface DialogData{
  reference: Reference,
  location: Location,
  brand: Brand,
  stock: Stock
}

@Component({
  selector: 'app-stock-drink-dialog',
  templateUrl: './stock-drink-dialog.component.html',
  styleUrls: ['./stock-drink-dialog.component.css']
})
export class StockDrinkDialogComponent implements OnInit {
  public qteToDrink: number = 1;
  public drinkLoad: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<StockDrinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _apiService: ApiService,
  ) { }

  ngOnInit(): void {
  }

  public btn_validDrink_click(): void{
    if(this.qteToDrink > 0){
      this.drinkLoad = true;
      this._apiService.drinkSomeBeer(this.data.stock, this.qteToDrink).subscribe(
        (result: boolean)=>{
          if(result){
            this.dialogRef.close(true);
          }
        },
        ()=>{
          this.drinkLoad = false;
        },
        ()=>{
          this.drinkLoad = false;
        }
      );
    }
  }

  public upQteToDrink(){
    if(this.data.stock.quantite > this.qteToDrink)
    {
      this.qteToDrink++;
    }
  }

  public downQteToDrink(){
    if(this.qteToDrink > 0)
    {
      this.qteToDrink--;
    }
  }

}
