<h1>{{ dialogTitle }}</h1>
<mat-dialog-content>
    <div *ngIf="onLoad" class="py-3 d-flex justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!onLoad">
        <form>
            <div class="d-flex justify-content-center">
                <mat-form-field>
                    <mat-label>Nom du lieu</mat-label>
                    <input matInput type="text" [formControl]="input_locationName_value">
                </mat-form-field>
            </div>
            <div class="d-flex justify-content-center">
                <mat-form-field>
                    <mat-label>Est sous-lieu de</mat-label>
                    <mat-select [(value)]="subLocationSelected">
                        <mat-option *ngFor="let someLocation of allLocations" [value]="someLocation">
                            <ng-template [ngIf]="someLocation.sublocation != null" [ngIfElse]="noSubLocation">
                                <span *ngFor="let someSubLocation of allLocations">
                                    <span *ngIf="someLocation.sublocation == someSubLocation.id">
                                        {{ someSubLocation.libelle }} > {{ someLocation.libelle }}
                                    </span>
                                </span>
                            </ng-template>
                            <ng-template #noSubLocation>
                                {{ someLocation.libelle }}
                            </ng-template>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-raised-button (click)="btn_valid_click()">Valider</button>
</mat-dialog-actions>
