import { Injectable } from '@angular/core';
import { Users } from '../models/users';
import { Location } from '../models/location';
import { Subject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Stock } from '../models/stock';
import { Brand } from '../models/brand';
import { Reference } from '../models/reference';

const httpOptions = {
  headers: new HttpHeaders({
    'accept': 'application/json',
    'Content-Type': 'application/json'
  })
}

interface apiLocationFormat{
  id: number,
  libelle: string,
  sublocation: string
}

interface apiStockFormat{
  id: number,
  qte: number,
  location: string,
  reference: string
}

interface apiBrandFormat{
  id: number,
  libelle: string,
  logo: string
}

interface apiReferenceFormat{
  id: number,
  libelle: string,
  contenance: number,
  brand: string,
  image: string
}

interface apiUsersFromat{
  id: number,
  fullname: string,
  username: string,
  password: string,
  role: string,
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _baseUrlApi: string = "http://clementdussollier.com/Projets/Beer_Stock/API/public/api";
  private _baseUrlApiLocations: string = this._baseUrlApi + "/locations";
  private _baseUrlApiStocks: string = this._baseUrlApi + "/stocks";
  private _baseUrlApiBrands: string = this._baseUrlApi + "/brands";
  private _baseUrlApiReferences: string = this._baseUrlApi + "/references";
  private _baseUrlApiUsers: string = this._baseUrlApi + "/users";

  constructor(
    private _http: HttpClient,
  ) { }

  // Location
  public getLocationByUser(theUser: Users, idSublocation: number): Observable<Location[]>{
    let allLocations: Subject<Location[]> = new Subject<Location[]>();
    this._http.get(this._baseUrlApiLocations + "/byUser/" + theUser.id + "/" + idSublocation, httpOptions).subscribe((allLocationsApi: apiLocationFormat[])=>{
      let newAllLocations: Location[] = [];
      allLocationsApi.forEach((someLocation: apiLocationFormat)=>{
        let theLocation: Location = new Location;
        theLocation.id = someLocation.id;
        theLocation.libelle = someLocation.libelle;
        if(someLocation.sublocation != (null && undefined && 0)){
          theLocation.sublocation = parseInt(someLocation.sublocation.split('/')[someLocation.sublocation.split('/').length-1]);
        }
        newAllLocations.push(theLocation);
      });
      allLocations.next(newAllLocations);
    });
    return allLocations.asObservable();
  }

  public countSublocations(theUser: Users, idSublocation: number): Observable<number>{
    let countSublocations: Subject<number> = new Subject<number>();
    this._http.get(this._baseUrlApiLocations + "/count/" + theUser.id + "/" + idSublocation, httpOptions).subscribe((theCount: number)=>{
      countSublocations.next(theCount);
    });
    return countSublocations.asObservable();
  }

  public getAllLocations(): Observable<Location[]>{
    let allLocations: Subject<Location[]> = new Subject<Location[]>();
    this._http.get(this._baseUrlApiLocations, httpOptions).subscribe((allLocationsFromApi: apiLocationFormat[])=>{
      let newAllLocations: Location[] = [];
      allLocationsFromApi.forEach((someLocation: apiLocationFormat)=>{
        let theLocation: Location = new Location;
        theLocation.id = someLocation.id;
        theLocation.libelle = someLocation.libelle;
        if(someLocation.sublocation != (null && undefined && 0)){
          theLocation.sublocation = parseInt(someLocation.sublocation.split('/')[someLocation.sublocation.split('/').length-1]);
        }
        newAllLocations.push(theLocation);
      });
      allLocations.next(newAllLocations);
    });
    return allLocations.asObservable();
  }

  public postLocation(location: Location): Observable<boolean>{
    let operationEnded: Subject<boolean> = new Subject<boolean>();
    let theLocationJson: string;
    if(location.sublocation == (null || undefined)){
      theLocationJson = '{'
      +'"libelle":"' + location.libelle + '"'
      +'}';
    }else{
      theLocationJson = '{'
      +'"libelle":"' + location.libelle + '",'
      +'"sublocation":"' + this._baseUrlApiLocations + '/' + location.sublocation + '"'
      +'}';
    }
    
    this._http.post(this._baseUrlApiLocations, theLocationJson, httpOptions).subscribe(()=>{
      operationEnded.next(true);
    });
    return operationEnded.asObservable();
  }



  // Stock
  public getStockByLocation(theLocation: Location): Observable<Stock[]>{
    let allStock: Subject<Stock[]> = new Subject<Stock[]>();
    this._http.get(this._baseUrlApiStocks + "/byLocation/" + theLocation.id, httpOptions).subscribe((allStocksFromApi: apiStockFormat[])=>{
      let newAllStock: Stock[] = [];
      allStocksFromApi.forEach((someStock: apiStockFormat)=>{
        let theStock: Stock = new Stock();
        theStock.id = someStock.id;
        theStock.quantite = someStock.qte;
        theStock.location = parseInt(someStock.location.split('/')[someStock.location.split('/').length-1]);
        theStock.reference = parseInt(someStock.reference.split('/')[someStock.reference.split('/').length-1]);
        newAllStock.push(theStock);
      });
      allStock.next(newAllStock);
    });
    return allStock.asObservable();
  }

  public drinkSomeBeer(theStock: Stock, quantite: number = 1): Observable<boolean>{
    let actionSuccess: Subject<boolean> = new Subject<boolean>();
    if(quantite > 0){
      for(let i=0; i<quantite; i++){
        theStock.quantite--;
      }
    }else{
      theStock.quantite--;
    }
    let theStockJson: string = '{"qte":' + theStock.quantite + '}';
    this._http.put(this._baseUrlApiStocks + "/" + theStock.id, theStockJson, httpOptions).subscribe(
      ()=>{},
      ()=>{},
      ()=>{actionSuccess.next(true)}
    );
    return actionSuccess.asObservable();
  }

  public fillStock(theStock: Stock, location: Location): Observable<boolean>{
    let operationEnded: Subject<boolean> = new Subject<boolean>();
    this.getStockByLocation(location).subscribe((allStock: Stock[])=>{
      let makePost: boolean = false;
      if(allStock.length > 0){
        allStock.forEach((someStock: Stock)=>{
          if(someStock.location == theStock.location && someStock.reference == theStock.reference){
            theStock.id = someStock.id;
            theStock.quantite += someStock.quantite;
          }
        });
        if(theStock.id == null){
          makePost = true;
        }
      }else{
        makePost = true;
      }

      let theStockJson: string = '{'
        +'"qte":' + theStock.quantite + ','
        +'"location":"' + this._baseUrlApiLocations + '/' + theStock.location + '",'
        +'"reference":"' + this._baseUrlApiReferences + '/' + theStock.reference + '"'
        +'}';
      if(makePost){
        this._http.post(this._baseUrlApiStocks, theStockJson, httpOptions).subscribe(()=>{
          operationEnded.next(true);
        });
      }else{
        this._http.put(this._baseUrlApiStocks + "/" + theStock.id, theStockJson, httpOptions).subscribe(()=>{
          operationEnded.next(true);
        });
      }
    });
    return operationEnded.asObservable();
  }

  public transferStock(oldStock: Stock, newStock: Stock, oldLocation: Location, newLocation: Location, quantite: number): Observable<string>{
    let operationSuccess: Subject<string> = new Subject<string>();
    operationSuccess.next('error');
    //controler si oldStock existe
    //controller si le stock de destination esicte (POST ou PUT)
    //retourner le string selon l'état d'erreur ou de succès
    this.getStockByLocation(oldLocation).subscribe((allStock: Stock[])=>{
      let oldStockIsGood: boolean = false;
      if(allStock.length > 0){
        allStock.forEach((someStock: Stock)=>{
          if(someStock.location == oldStock.location && someStock.reference == oldStock.reference){
            oldStock.id = someStock.id;
            oldStock.quantite = someStock.quantite;
            if(someStock.quantite >= quantite){
              oldStockIsGood = true;
            }else{
              operationSuccess.next('oldStockQuantiteInvalid');
            }
          }
        });
        if(oldStock.id != null){
          this.getStockByLocation(newLocation).subscribe((AllStock: Stock[])=>{
            AllStock.forEach((someStock: Stock)=>{
              if(someStock.location == newStock.location && someStock.reference == newStock.reference){
                newStock.id = someStock.id;
                newStock.quantite = someStock.quantite;
              }
            });
            if(newStock.id == null){
              newStock.quantite = quantite;
            }else{
              newStock.quantite += quantite;
            }

            if(oldStockIsGood){
              let theStockJson: string = '{'
                +'"qte":' + newStock.quantite + ','
                +'"location":"' + this._baseUrlApiLocations + '/' + newStock.location + '",'
                +'"reference":"' + this._baseUrlApiReferences + '/' + newStock.reference + '"'
                +'}';
              this.drinkSomeBeer(oldStock, quantite);
              if(newStock.id == null){
                this._http.post(this._baseUrlApiStocks, theStockJson, httpOptions).subscribe(()=>{
                  operationSuccess.next('success');
                });
              }else if(newStock.id != null){
                this._http.put(this._baseUrlApiStocks + "/" + newStock.id, theStockJson, httpOptions).subscribe(()=>{
                  operationSuccess.next('success');
                });
              }
            }
          });
        }else{
          operationSuccess.next('oldStockNotExists');
        }
      }else{
        operationSuccess.next('oldStockNotExists');
      }
    });
    return operationSuccess.asObservable();
  }



  // Brands
  public getAllBrands(): Observable<Brand[]>{
    let allBrands: Subject<Brand[]> = new Subject<Brand[]>();
    this._http.get(this._baseUrlApiBrands, httpOptions).subscribe((allBrandsFromApi: apiBrandFormat[])=>{
      let newAllBrands: Brand[] = [];
      allBrandsFromApi.forEach((someBrand: apiBrandFormat)=>{
        let theBrand: Brand = new Brand();
        theBrand.id = someBrand.id;
        theBrand.libelle = someBrand.libelle;
        theBrand.logo = someBrand.logo;
        newAllBrands.push(theBrand);
      });
      allBrands.next(newAllBrands);
    });
    return allBrands.asObservable();
  }

  public postBrand(brand: Brand): Observable<boolean>{
    let operationEnded: Subject<boolean> = new Subject<boolean>();
    let brandJson: string = '{'
      +'"libelle":"' + brand.libelle + '",'
      +'"logo":"' + brand.logo + '"'
      +'}';
    this._http.post(this._baseUrlApiBrands, brandJson, httpOptions).subscribe(()=>{
      operationEnded.next(true);
    });
    return operationEnded.asObservable();
  }

  public putBrand(brand: Brand): Observable<boolean>{
    let operationEnded: Subject<boolean> = new Subject<boolean>();
    let brandJson: string = '{'
      +'"libelle":"' + brand.libelle + '",'
      +'"logo":"' + brand.logo + '"'
      +'}';
    this._http.put(this._baseUrlApiBrands + '/' + brand.id, brandJson, httpOptions).subscribe(()=>{
      operationEnded.next(true);
    });
    return operationEnded.asObservable();
  }




  // References
  public getAllReferences(): Observable<Reference[]>{
    let allReferences: Subject<Reference[]> = new Subject<Reference[]>();
    this._http.get(this._baseUrlApiReferences, httpOptions).subscribe((allReferencesFromApi: apiReferenceFormat[])=>{
      let newAllReferences: Reference[] = [];
      allReferencesFromApi.forEach((someReference: apiReferenceFormat)=>{
        let theReference: Reference = new Reference();
        theReference.id = someReference.id;
        theReference.libelle = someReference.libelle;
        theReference.contenance = someReference.contenance;
        theReference.brand = parseInt(someReference.brand.split('/')[someReference.brand.split('/').length-1]);
        theReference.image = someReference.image;
        newAllReferences.push(theReference);
      });
      allReferences.next(newAllReferences);
    });
    return allReferences.asObservable();
  }


  public getReferencesByBrand(brand: Brand): Observable<Reference[]>{
    let allReferences: Subject<Reference[]> = new Subject<Reference[]>();
    this._http.get(this._baseUrlApiReferences + "/byBrand/" + brand.id, httpOptions).subscribe((allRefFromApi: apiReferenceFormat[])=>{
      allReferences.next(this.apiToObjectReferences(allRefFromApi));
    });
    return allReferences.asObservable();
  }


  private apiToObjectReferences(allRefFromApi: apiReferenceFormat[]): Reference[]{
    let newAllReferences: Reference[] = [];
    allRefFromApi.forEach((someReference: apiReferenceFormat)=>{
      let theReference: Reference = new Reference();
      theReference.id = someReference.id;
      theReference.libelle = someReference.libelle;
      theReference.contenance = someReference.contenance;
      theReference.brand = parseInt(someReference.brand.split('/')[someReference.brand.split('/').length-1]);
      theReference.image = someReference.image;
      newAllReferences.push(theReference);
    });
    return newAllReferences;
  }

  public postReference(reference: Reference): Observable<boolean>{
    let operationEnded: Subject<boolean> = new Subject<boolean>();
    let theRefJson: string = '{'
      +'"libelle":"' + reference.libelle + '",'
      +'"contenance":' + reference.contenance + ','
      +'"brand":"' + this._baseUrlApiBrands + '/' + reference.brand + '",'
      +'"image":"' + reference.image + '"'
      +'}';
    this._http.post(this._baseUrlApiReferences, theRefJson, httpOptions).subscribe(()=>{
      operationEnded.next(true);
    });
    return operationEnded.asObservable();
  }




  // Users
  public getUserByUsername(username: string): Observable<Users>{
    let theUser: Subject<Users> = new Subject<Users>();
    this._http.get(this._baseUrlApiUsers + "/byPseudo/" + username, httpOptions).subscribe((theUserFromApi: apiUsersFromat[])=>{
      let theNewUser: Users = new Users();
      if(theUserFromApi.length > 0){
        theNewUser.id = theUserFromApi[0].id;
        theNewUser.fullName = theUserFromApi[0].fullname;
        theNewUser.username = theUserFromApi[0].username;
        theNewUser.password = theUserFromApi[0].password;
        theNewUser.role = theUserFromApi[0].role.split('/')[theUserFromApi[0].role.split('/').length-1];
      }
      theUser.next(theNewUser);
    });
    return theUser.asObservable();
  }
}
