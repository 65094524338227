import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/services/api.service';
import { Location } from 'src/app/shared/models/location';
import { Brand } from 'src/app/shared/models/brand';
import { Reference } from 'src/app/shared/models/reference';
import { LoginService } from 'src/app/shared/services/login.service';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { FormControl } from '@angular/forms';
import { Stock } from 'src/app/shared/models/stock';

interface DialogData{
  location: Location
  type: 'fill' | 'transfer'
}

interface SubLocation{
  locations: Location[];
  selectedLocation: Location;
}

@Component({
  selector: 'app-add-stock',
  templateUrl: './add-stock.component.html',
  styleUrls: ['./add-stock.component.css']
})
export class AddStockComponent implements OnInit {
  public onLoad: boolean;
  public referenceLoad: boolean;
  public brandLoad: boolean;
  public locationLoad: boolean;
  public locationTransferLoad: boolean;

  public allLocations: Location[];
  public allBrands: Brand[];
  public allReferences: Reference[];

  public selectedLocationId: number;
  public selectedLocation: Location;
  public selectedLocationTransfer: Location;
  public selectedBrandId: number;
  public selectedBrand: Brand;
  public selectedReferenceId: number;
  public selectedReference: Location;
  public quantite: FormControl = new FormControl();

  public sublocations: SubLocation[] = [];
  public sublocationsTransfer: SubLocation[] = [];

  constructor(
    private dialogRef: MatDialogRef<AddStockComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _apiService: ApiService,
    private _loginService: LoginService,
    private _functionsService: FunctionsService,
  ) { }

  ngOnInit(): void {
    this.onLoad = true;
    this.selectedLocationId = this.data.location.id;
    this.fillAllSelectList();
    this.referenceLoad = false;
  }

  private fillAllSelectList(): void{
    this._apiService.getLocationByUser(this._loginService.loggedUser, 0).subscribe((allLocationsApi: Location[])=>{
      this.allLocations = allLocationsApi;
      this._apiService.getAllBrands().subscribe((allBrandsFromApi: Brand[])=>{
        this.allBrands = allBrandsFromApi;
        this.onLoad = false;
      });
    });
  }

  public btn_validFill_click(): void{
    if(this.selectedLocation == (undefined || null)){
      this._functionsService.openErrorSnackBar("Sélectionnez un lieu");
    }else if(this.selectedBrand == (undefined || null)){
      this._functionsService.openErrorSnackBar("Sélectionnez une marque");
    }else if(this.selectedReference == (undefined || null)){
      this._functionsService.openErrorSnackBar("Sélectionnez une référence");
    }else if(this.quantite.value == (undefined || null)){
      this._functionsService.openErrorSnackBar("Saisissez une quantité");
    }else if(this.quantite.value <= 0){
      this._functionsService.openErrorSnackBar("Saisissez une quantité supérieure à 0");
    }else{
      let theStock: Stock = new Stock();
      let lastLocation: Location;
      if(this.sublocations.length > 0){
        if(this.sublocations[this.sublocations.length-1].selectedLocation == (undefined || null)){
          this._functionsService.openErrorSnackBar("Sélectionnez le (ou les) sous-lieu");
        }else{
          theStock.location = this.sublocations[this.sublocations.length-1].selectedLocation.id;
          lastLocation = this.sublocations[this.sublocations.length-1].selectedLocation;
        }
      }else{
        theStock.location = this.selectedLocation.id;
        lastLocation = this.selectedLocation;
      }
      theStock.reference = this.selectedReference.id;
      theStock.quantite = this.quantite.value;
      this.onLoad = true;
      this._apiService.fillStock(theStock, lastLocation).subscribe((ended: boolean)=>{
        if(ended){
          this.dialogClose();
          this._functionsService.openSuccessSnackBar("Stock ajouté");
        }
      });
    }
  }

  btn_validTransfer_click(): void{
    if(this.selectedLocation == (undefined || null)){
      this._functionsService.openErrorSnackBar("Sélectionnez un lieu");
    }else if(this.selectedBrand == (undefined || null)){
      this._functionsService.openErrorSnackBar("Sélectionnez une marque");
    }else if(this.selectedReference == (undefined || null)){
      this._functionsService.openErrorSnackBar("Sélectionnez une référence");
    }else if(this.quantite.value == (undefined || null)){
      this._functionsService.openErrorSnackBar("Saisissez une quantité");
    }else if(this.quantite.value <= 0){
      this._functionsService.openErrorSnackBar("Saisissez une quantité supérieure à 0");
    }else{
      let theStock: Stock = new Stock();
      let lastLocation: Location;
      if(this.sublocations.length > 0){
        if(this.sublocations[this.sublocations.length-1].selectedLocation == (undefined || null)){
          this._functionsService.openErrorSnackBar("Sélectionnez le (ou les) sous-lieu");
        }else{
          theStock.location = this.sublocations[this.sublocations.length-1].selectedLocation.id;
          lastLocation = this.sublocations[this.sublocations.length-1].selectedLocation;
        }
      }else{
        theStock.location = this.selectedLocation.id;
        lastLocation = this.selectedLocation;
      }
      theStock.reference = this.selectedReference.id;
      theStock.quantite = this.quantite.value;

      let theNewStock: Stock = new Stock();
      let lastLocationTransfer: Location;
      if(this.sublocationsTransfer.length > 0){
        if(this.sublocationsTransfer[this.sublocationsTransfer.length-1].selectedLocation == (undefined || null)){
          this._functionsService.openErrorSnackBar("Sélectionnez le (ou les) sous-lieu du transfert");
        }else{
          theNewStock.location = this.sublocationsTransfer[this.sublocationsTransfer.length-1].selectedLocation.id;
          lastLocationTransfer = this.sublocationsTransfer[this.sublocationsTransfer.length-1].selectedLocation;
        }
      }else{
        theNewStock.location = this.selectedLocationTransfer.id;
        lastLocationTransfer = this.selectedLocationTransfer;
      }
      theNewStock.reference = this.selectedReference.id;
      theNewStock.quantite = this.quantite.value;

      this.onLoad = true;
      this._apiService.transferStock(theStock, theNewStock, lastLocation, lastLocationTransfer, this.quantite.value).subscribe((result: string)=>{
        switch(result){
          case 'success':
            this.dialogClose();
            this._functionsService.openSuccessSnackBar("Stock transféré");
            break;
          case 'oldStockNotExists':
            this._functionsService.openErrorSnackBar("Informations du lieu de débit non valides");
            break;
          case 'oldStockQuantiteInvalid':
            this._functionsService.openErrorSnackBar("Quantité invalide");
            break;
        }
        this.onLoad = false;
      });
    }
  }

  private dialogClose(): void{
    this.dialogRef.close(true);
  }

  public select_location_change(index: number = null): void{
    if(index == null){
      this.sublocations = [];
      this.searchSublocation(this.selectedLocation);
    }else{
      this.sublocations.splice(index+1);
      this.searchSublocation(this.sublocations[index].selectedLocation);
    }
  }

  private searchSublocation(location: Location): void{
    this._apiService.countSublocations(this._loginService.loggedUser, location.id).subscribe((count: number)=>{
      this.locationLoad = true;
      if(count > 0){
        this._apiService.getLocationByUser(this._loginService.loggedUser, location.id).subscribe((allLocations: Location[])=>{
          this.sublocations.push({locations: allLocations, selectedLocation: null});
        });
      }
      this.locationLoad = false;
    });
  }

  public select_locationTransfer_change(index: number = null): void{
    if(index == null){
      this.sublocationsTransfer = [];
      this.searchSublocationTransfer(this.selectedLocationTransfer);
    }else{
      this.sublocations.splice(index+1);
      this.searchSublocationTransfer(this.sublocationsTransfer[index].selectedLocation);
    }
  }

  private searchSublocationTransfer(location: Location): void{
    this._apiService.countSublocations(this._loginService.loggedUser, location.id).subscribe((count: number)=>{
      this.locationTransferLoad = true;
      if(count > 0){
        this._apiService.getLocationByUser(this._loginService.loggedUser, location.id).subscribe((allLocations: Location[])=>{
          this.sublocationsTransfer.push({locations: allLocations, selectedLocation: null});
        });
      }
      this.locationTransferLoad = false;
    });
  }

  public select_brand_change(): void{
    this._apiService.getReferencesByBrand(this.selectedBrand).subscribe((allreferencesFromApi: Reference[])=>{
      this.referenceLoad = true;
      this.selectedReference = null;
      this.allReferences = allreferencesFromApi;
      this.referenceLoad = false;
    });
  }

  public select_reference_change(): void{

  }

}
