<div class="mt-5">
    <div class="d-flex justify-content-center">
        <button mat-raised-button (click)="btn_addRef_click()">Ajouter une référence</button>
    </div>
    <div class="mt-4 d-flex justify-content-center" *ngIf="onLoad">
        <mat-spinner></mat-spinner>
    </div>
    <div class="mt-4 text-center">
        <div *ngFor="let someBrand of allBrands">
            <h4 class="mt-2 mb-0 font-weight-bold">{{ someBrand.libelle }}</h4>
            <table class="mx-auto">
                <tr *ngFor="let someRef of allReferences">
                    <span *ngIf="someBrand.id == someRef.brand">
                        <td class="p-2">{{ someRef.libelle }}</td>
                        <td class="p-2">{{ someRef.contenance }} cl</td>
                    </span>
                </tr>
            </table>
        </div>
    </div>
</div>
