import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Users } from '../../models/users';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-user-connected-info',
  templateUrl: './user-connected-info.component.html',
  styleUrls: ['./user-connected-info.component.css']
})
export class UserConnectedInfoComponent implements OnInit {
  public loggedUser: Users;

  constructor(
    private _loginService: LoginService,
    private _navigationService: NavigationService,
  ) { }

  ngOnInit(): void {
    this.loggedUser = this._loginService.loggedUser;
  }

  btn_settings_click(): void{
    this._navigationService.setActivateHomeView('settings');
  }

}
