export class Location {
    private _id: number;
    private _libelle: string;
    private _sublocation: number;

    constructor(){
        this.id = null;
        this.libelle = null;
        this.sublocation = null;
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter libelle
     * @return {string}
     */
	public get libelle(): string {
		return this._libelle;
	}

    /**
     * Getter sublocation
     * @return {number}
     */
	public get sublocation(): number {
		return this._sublocation;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter libelle
     * @param {string} value
     */
	public set libelle(value: string) {
		this._libelle = value;
	}

    /**
     * Setter sublocation
     * @param {number} value
     */
	public set sublocation(value: number) {
		this._sublocation = value;
	}
}
