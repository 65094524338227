import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Users } from '../models/users';
import { NavigationService } from './navigation.service';

interface jsonUsersFromat{
  _id: number,
  _fullName: string,
  _username: string,
  _password: string,
  _role: string,
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private _isConnected: Subject<boolean> = new Subject<boolean>();
  
  public loggedUser: Users;

  constructor(
    private _navigationService: NavigationService,
  ) {
    this._isConnected.next(false);
    this.loggedUser = new Users();
    console.log(JSON.parse(localStorage.getItem('connectedUser')));
  }

  public init(): void{
    if(localStorage.getItem('connectedUser') != null){
      console.log('hello')
      this.setUser(JSON.parse(localStorage.getItem("connectedUser")));
      this.setConnectedState(true);
    }
  }

  public isConnected(): Observable<boolean>{
    return this._isConnected.asObservable();
  }

  public setConnectedState(isConnected: boolean){
    this._isConnected.next(isConnected);
  }

  public setUser(theUser: Users): void{
    this.loggedUser = theUser;
    this.setConnectedState(true);
    localStorage.setItem("connectedUser", JSON.stringify(theUser));
  }

  public disconnect(): void{
    this.setConnectedState(false);
    this.loggedUser = new Users();
    localStorage.removeItem('connectedUser');
    //localStorage.clear(); -> supprime touts les cookies
    this._navigationService.resetNavigation();
  }
}
