import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/shared/services/login.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { HomeView } from 'src/app/shared/models/home-view';
import { Location } from 'src/app/shared/models/location';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public isConnected: boolean;
  public activatedHomeView: HomeView;
  public stockLocation: Location;

  constructor(
    private _loginService: LoginService,
    private _navigationService: NavigationService,
  ) {
    this.activatedHomeView = {name: 'location-selection'};
    console.log('the test')
  }

  ngOnInit(): void {
    this._loginService.init();
    if(localStorage.getItem("connectedUser") != null){
      this._loginService.setUser(JSON.parse(localStorage.getItem("connectedUser")));
      this._loginService.setConnectedState(true);
    }


    this._loginService.isConnected().subscribe((data: boolean)=>{
      this.isConnected = data;
      console.log(this.isConnected)
    });


    this._navigationService.getActivateHomeView().subscribe((data: HomeView)=>{
      this.activatedHomeView = data;
    });

    this._navigationService.getStockLocation().subscribe((data: Location)=>{
      this.stockLocation = data;
    });
  }

}
