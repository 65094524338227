export class Stock {
    private _id: number;
    private _location: number;
    private _reference: number;
    private _quantite: number;

    constructor(){
        this.id = null;
        this.location = null;
        this.reference = null;
        this.quantite = null;
    }


    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter location
     * @return {number}
     */
	public get location(): number {
		return this._location;
	}

    /**
     * Getter reference
     * @return {number}
     */
	public get reference(): number {
		return this._reference;
	}

    /**
     * Getter quantite
     * @return {number}
     */
	public get quantite(): number {
		return this._quantite;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter location
     * @param {number} value
     */
	public set location(value: number) {
		this._location = value;
	}

    /**
     * Setter reference
     * @param {number} value
     */
	public set reference(value: number) {
		this._reference = value;
	}

    /**
     * Setter quantite
     * @param {number} value
     */
	public set quantite(value: number) {
		this._quantite = value;
	}

}
