import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { Location } from 'src/app/shared/models/location';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'app-location-selection',
  templateUrl: './location-selection.component.html',
  styleUrls: ['./location-selection.component.css']
})
export class LocationSelectionComponent implements OnInit {
  public locationsToDisplay: Location[] = [];
  public onLoad: boolean = false;

  constructor(
    private _apiService: ApiService,
    private _loginService: LoginService,
    private _navigationService: NavigationService,
  ) { }

  ngOnInit(): void {
    this.refreshLocationsToDisplay(0);
  }

  private refreshLocationsToDisplay(idSublocation: number): void{
    this.onLoad = true;
    this._apiService.getLocationByUser(this._loginService.loggedUser, idSublocation).subscribe((allLocations: Location[])=>{
      this.locationsToDisplay = [];
      this.locationsToDisplay = allLocations;
      this.onLoad = false;
    });
  }

  public btn_selectLocation_click(theLocation: Location): void{
    this._apiService.countSublocations(this._loginService.loggedUser, theLocation.id).subscribe((theCount: number)=>{
      if(theCount > 0){
        this.refreshLocationsToDisplay(theLocation.id);
      }else{
        this._navigationService.selectionToStock(theLocation);
      }
    });
  }

}
