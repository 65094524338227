<div class="container">
    <div *ngIf="!isConnected">
        <app-login></app-login>
    </div>
    <div *ngIf="isConnected">
        <app-user-connected-info></app-user-connected-info>
        <div *ngIf="activatedHomeView.name == 'location-selection'">
            <app-location-selection></app-location-selection>
        </div>
        <div *ngIf="activatedHomeView.name == 'stock'">
            <app-stock [stockLocation]="stockLocation"></app-stock>
        </div>
        <div *ngIf="activatedHomeView.name == 'settings'">
            <app-settings></app-settings>
        </div>
    </div>
</div>
