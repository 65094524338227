<div class="w-100 login-page d-flex justify-content-center align-items-center">
    <div class="p-3 border border-black rounded">
        <div class="mb-5 d-flex justify-content-center">
            <img [src]="logo">
        </div>
        <form (submit)="btn_connection_click()">
            <div>
                <div>
                    <mat-form-field class="example-full-width w-100">
                        <mat-label>Pseudo</mat-label>
                        <input matInput [formControl]="input_pseudo_value">
                    </mat-form-field>
                </div>
            
                <div>
                    <mat-form-field class="example-full-width w-100">
                        <mat-label>Mot de passe</mat-label>
                        <input matInput type="password" [formControl]="input_password_value">
                    </mat-form-field>
                </div>

                <mat-progress-bar mode="indeterminate" class="mb-3" *ngIf="loginLoad"></mat-progress-bar>

                <div class="text-right mt-2">
                    <button mat-raised-button type="submit" [disabled]="loginLoad ? true : false">{{ btn_connection_content }}</button>
                </div>
            </div>
        </form>
    </div>
</div>
