import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HomeView } from '../models/home-view';
import { Location } from '../models/location';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public activateHomeView: Subject<HomeView> = new Subject<HomeView>();
  public stockLocation: Subject<Location> = new Subject<Location>();

  constructor() {
    this.activateHomeView.next({name : 'location-selection'});
  }

  public selectionToStock(location: Location){
    this.activateHomeView.next({ name: 'stock'});
    this.stockLocation.next(location);
  }

  public getActivateHomeView(): Observable<HomeView>{
    return this.activateHomeView.asObservable();
  }

  public setActivateHomeView(newParam: string): void{
    switch(newParam){
      case 'settings':
        this.activateHomeView.next({name: 'settings'});
        break;
      case 'location-selection':
        this.activateHomeView.next({name: 'location-selection'});
        break;
    }
  }

  public getStockLocation(): Observable<Location>{
    return this.stockLocation.asObservable();
  }

  public resetNavigation(): void{
    this.activateHomeView.next({name: 'location-selection'});
  }

  public backToSelect(subLocationId: number): void{
    this.activateHomeView.next({name: 'location-selection'});
  }
}
