import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  public selectedSetting: 'home' | 'brands' | 'references' | 'locations';

  constructor(
    private _navigationService: NavigationService,
    private _loginService: LoginService,
  ) {
    this.selectedSetting = 'home';
  }

  ngOnInit(): void {
  }

  public btn_backToHome_click(): void{
    this._navigationService.setActivateHomeView('location-selection');
  }

  public btn_backToSettings_click(): void{
    this.selectedSetting = 'home';
  }

  public btn_brands_click(): void{
    this.selectedSetting = 'brands';
  }

  public btn_references_click(): void{
    this.selectedSetting = 'references';
  }

  public btn_locations_click(): void{
    this.selectedSetting = 'locations';
  }

  public btn_disconnect_click(): void{
    this._loginService.disconnect(); 
  }

}
