<div class="backBtn" (click)="btn_backToSelect_click()">
    <i class="fas fa-chevron-left"></i> Retour
</div>
<div class="pt-5">
    <div class="w-100 d-flex justify-content-end">
        <div class="btn-group" role="group">
            <button type="submit" class="btn btn-info" (click)="btn_addStock_click()">Ajouter du stock</button>
            <button type="submit" class="btn btn-info" (click)="btn_transferStock_click()">Transférer</button>
        </div>
    </div>
    <div class="d-flex h2">
        <span class="mx-auto">{{stockLocation.libelle}}</span>
    </div>
    <div *ngIf="onLoad" class="mt-4 d-flex justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!onLoad">
        <div *ngFor="let someBrand of allBrands">
            <span *ngIf="displayBrand(someBrand)">
                <div class="someBrand-title border rounded d-flex justify-content-center">
                    <ng-template [ngIf]="someBrand.logo != null && someBrand.logo != '' && someBrand.logo != 'null' && someBrand.logo != undefined" [ngIfElse]="noBrandLogo">
                        <img [src]="brandLogoPath + someBrand.logo" [alt]="someBrand.libelle"/>
                    </ng-template>
                    <ng-template #noBrandLogo>
                        <img [src]="brandLogoPath + 'brand_logo.png'" style="width: 20px !important; margin-right: 2px"/>
                        {{ someBrand.libelle }}
                    </ng-template>
                </div>
                <div class="mb-3 mx-3 border-right border-left border-bottom d-flex justify-content-center row">
                    <div *ngFor="let someRef of allReferences" [ngClass]="someRef.brand == someBrand.id && getStockQuantite(someRef) > 0 ? 'col-md-3 col-sm-6 col-6 text-center' : ''">
                        <span *ngIf="displayStock(someRef)">
                            <div *ngIf="someRef.brand == someBrand.id" class="py-2 someBrand-references border rounded my-2 mx-1 pl-0 row">
                                <div class="col-5 d-flex align-items-center">
                                    <ng-template [ngIf]="someRef.image != null && someRef.image != 'null' && someRef.image != '' && someRef.image != undefined" [ngIfElse]="noReferenceImage">
                                        <img [src]="referenceImagePath + someRef.image" class="w-100"/>
                                    </ng-template>
                                    <ng-template #noReferenceImage>
                                        <img [src]="referenceImagePath + 'no_brand.png'" width="100%"/>
                                    </ng-template>
                                </div>
                                <div class="col-7 d-flex align-items-center justify-content-center">
                                    <div>
                                        <div class="font-weight-bold">
                                            {{ someRef.libelle }}
                                        </div>
                                        <div>
                                            {{ someRef.contenance }} cl
                                        </div>
                                        <div>
                                            <span *ngFor="let someStock of allStock">
                                                <span *ngIf="someRef.id == someStock.reference">
                                                    <span *ngIf="someStock.quantite > 0" class="text-success">
                                                        <i class="fas fa-box-open"></i> {{ someStock.quantite }}
                                                    </span>
                                                    <span *ngIf="someStock.quantite <= 0" class="text-danger">
                                                        <i class="fas fa-box-open"></i> {{ someStock.quantite }}
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <span *ngFor="let someStock of allStock">
                                        <span *ngIf="someRef.id == someStock.reference">
                                            <div class="pt-2">
                                                <button mat-raised-button [disabled]="someStock.quantite <= 0 ? true : false" (click)="btn_drink_click(someRef, stockLocation, someBrand, someStock)">Boire</button>
                                            </div>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </span>
        </div>
    </div>
</div>
