<div class="mt-5">
    <div class="text-center">
        <button mat-raised-button (click)="btn_addLocation_click()">Ajouter un lieu</button>
    </div>
    <div class="mt-4 d-flex justify-content-center" *ngIf="onLoad">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngFor="let someLocation of allLocations" class="mt-4 text-center">
        <div *ngIf="someLocation.sublocation == null">
            <h4 class="mb-0 mt-2 font-weight-bold">{{ someLocation.libelle }}</h4>
            <span *ngFor="let someSubLocation of allLocations">
                <div *ngIf="someSubLocation.sublocation == someLocation.id">
                    {{ someSubLocation.libelle }}
                </div>
            </span>
        </div>
    </div>
</div>
