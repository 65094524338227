import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Brand } from 'src/app/shared/models/brand';
import { ApiService } from 'src/app/shared/services/api.service';
import { AddBrandComponent } from './add-brand/add-brand.component';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css']
})
export class BrandsComponent implements OnInit {
  public allBrands: Brand[];
  public onLoad: boolean = false;

  constructor(
    private _apiService: ApiService,
    private _dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.refreshAllBrands();
  }

  public refreshAllBrands(): void{
    this.onLoad = true;
    this._apiService.getAllBrands().subscribe((allBrandsFromApi: Brand[])=>{
      this.allBrands = allBrandsFromApi;
      this.onLoad = false;
    },
    ()=>{this.onLoad = false;},
    ()=>{this.onLoad = false;});
  }

  public btn_addBrand_click(): void{
    this.openDialog('add');
  }

  public btn_editBrand_click(someBrand: Brand): void{
    this.openDialog('edit', someBrand);
  }

  private openDialog(type: string, brand: Brand = null): void{
    const dialogRef = this._dialog.open(AddBrandComponent, {
      width: '500px',
      data: {type: type, brand: brand}
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result){
        this.refreshAllBrands();
      }
    });
  }

}
