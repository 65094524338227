import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { FormControl } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { Users } from '../../models/users';
import { FunctionsService } from '../../services/functions.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public input_pseudo_value: FormControl = new FormControl();
  public input_password_value: FormControl = new FormControl();

  public btn_connection_content: string = "Connexion";

  public logoFolder: string = "../../../../assets/images/";
  public logo: string = this.logoFolder + "beer-icon-text.svg";

  public loginLoad: boolean = false;

  constructor(
    private _loginService: LoginService,
    private _apiService: ApiService,
    private _functionsService: FunctionsService,
  ) { }

  ngOnInit(): void {
  }

  public btn_connection_click(): void{
    if((this.input_pseudo_value.value == null || this.input_pseudo_value.value == "") && (this.input_password_value.value == null || this.input_password_value.value == "")){
      this._functionsService.openErrorSnackBar("saisir pseudo et MDP");
    }else if(this.input_pseudo_value.value == null || this.input_pseudo_value.value == ""){
      this._functionsService.openErrorSnackBar("saisir pseudo");
    }else if(this.input_password_value.value == null || this.input_password_value.value == ""){
      this._functionsService.openErrorSnackBar("saisir MDP");
    }else{
      this.loginLoad = true;
      this._apiService.getUserByUsername(this.input_pseudo_value.value).subscribe((theUser: Users)=>{
        if(theUser.username == null){
          this._functionsService.openErrorSnackBar("pseudo inconnu")
          this.loginLoad = false;
        }else if(this.input_password_value.value != theUser.password){
          this._functionsService.openErrorSnackBar("MDP incorrect");
          this.loginLoad = false;
        }else{
          this._loginService.setUser(theUser);
          this.loginLoad = false;
        }
      }, ()=>{
        this.loginLoad = false;
      }, ()=>{
        this.loginLoad = false;
      });
    }
    //this._loginService.setConnectedState(true);
  }

}
