<h1>Validation</h1>
<mat-dialog-content>
    <div *ngIf="drinkLoad" class="py-3 d-flex justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!drinkLoad">
        <div>
            Veuillez valider de débit de :
        </div>
        <div>
            {{ data.brand.libelle }} - {{ data.reference.libelle }}
        </div>
        <div class=" mt-3 d-flex justify-content-center">
            <span class="btnChangeQte" (click)="downQteToDrink()">-</span>
            <span class="qte">{{ qteToDrink }}</span>
            <span class="btnChangeQte" (click)="upQteToDrink()">+</span>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="mt-3 d-flex justify-content-end">
    <button mat-raised-button [mat-dialog-close]="false">Annuler</button>
    <button mat-raised-button (click)="btn_validDrink_click()" cdkFocusInitial>Valider</button>
</mat-dialog-actions>
