<h1 mat-dialog-title>{{ dialogTitle }}</h1>
<mat-dialog-content>
    <div *ngIf="onLoad" class="py-3 d-flex justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!onLoad">
        <form class="d-flex justify-content-center">
            <mat-form-field>
                <mat-label>Nom de la marque</mat-label>
                <input matInput type="text" [formControl]="input_brandName_value"/>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-button mat-dialog-close>Annuler</button>
    <button mat-button (click)="btn_valid_click()">Valider</button>
</mat-dialog-actions>
