<div class="mt-5">
    <div class="d-flex justify-content-center">
        <button mat-raised-button (click)="btn_addBrand_click()">Ajouter une marque</button>
    </div>
    <div class="mt-4 d-flex justify-content-center" *ngIf="onLoad">
        <mat-spinner></mat-spinner>
    </div>
    <div class="mt-4 d-flex justify-content-center">
        
        <table>
            <tr>
                <th class="py-2 px-2">Marque</th>
                <th class="py-2 px-2 text-center">Editer</th>
            </tr>
            <tr *ngFor="let someBrand of allBrands">
                <td class="py-2 px-2">{{ someBrand.libelle }}</td>
                <td class="py-2 px-2">
                    <button mat-button (click)="btn_editBrand_click(someBrand)"><i class="far fa-edit"></i></button>
                </td>
            </tr>
        </table>
    </div>
</div>
