import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { Location } from 'src/app/shared/models/location';
import { ApiService } from 'src/app/shared/services/api.service';
import { AddLocationComponent } from './add-location/add-location.component';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {
  public allLocations: Location[];
  public onLoad: boolean = false;

  constructor(
    private _apiService: ApiService,
    private _dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.refreshAllLocations();
  }

  private refreshAllLocations(): void{
    this.onLoad = true;
    this._apiService.getAllLocations().subscribe((allLocationsFromApi: Location[])=>{
      this.allLocations = allLocationsFromApi;
      this.onLoad = false;
    },
    ()=>{this.onLoad = false;},
    ()=>{this.onLoad = false;});
  }

  public btn_addLocation_click(): void{
    this.openDialog('add');
  }

  private openDialog(type: string, location: Location = null): void{
    const dialogRef = this._dialog.open(AddLocationComponent, {
      width: '500px',
      data: {type: type, location: location}
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result){
        this.refreshAllLocations();
      }
    });
  }

}
