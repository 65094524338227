import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Brand } from 'src/app/shared/models/brand';
import { FormControl } from '@angular/forms';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { ApiService } from 'src/app/shared/services/api.service';

interface DialogData{
  type: string,
  brand?: Brand
}

@Component({
  selector: 'app-add-brand',
  templateUrl: './add-brand.component.html',
  styleUrls: ['./add-brand.component.css']
})
export class AddBrandComponent implements OnInit {
  public dialogTitle: string;
  public onLoad: boolean = false;

  public input_brandName_value: FormControl = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<AddBrandComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _functionsService: FunctionsService,
    private _apiService: ApiService,
  ) {
    switch(data.type){
      case 'add':
        this.dialogTitle = "Ajouter";
        break;
      case 'edit':
        this.dialogTitle = "Modifier";
        break;
    }
    this.dialogTitle += " une marque";
  }

  ngOnInit(): void {
    if(this.data.type == 'edit'){
      this.input_brandName_value = new FormControl(this.data.brand.libelle);
    }
  }

  public btn_valid_click(): void{
    if(this.input_brandName_value.value == (null || "")){
      this._functionsService.openErrorSnackBar("Saisir un nom de marque");
    }else{
      switch(this.data.type){
        case 'add':
          let theBrand: Brand = new Brand();
          theBrand.libelle = this.input_brandName_value.value;
          this.onLoad = true;
          this._apiService.postBrand(theBrand).subscribe((result: boolean)=>{
            if(result){
              this.dialogRef.close(result);
            }
            this.onLoad = false;
          },
          ()=>{this.onLoad = false;},
          ()=>{this.onLoad = false;},);
          break;
        case 'edit':
          this.data.brand.libelle = this.input_brandName_value.value;
          this.onLoad = true;
          this._apiService.putBrand(this.data.brand).subscribe((result: boolean)=>{
            if(result){
              this.dialogRef.close(result);
            }
            this.onLoad = false;
          },
          ()=>{this.onLoad = false;},
          ()=>{this.onLoad = false;},);
          break;
      }

    }
  }

}
