import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { Brand } from 'src/app/shared/models/brand';
import { Reference } from 'src/app/shared/models/reference';
import { Stock } from 'src/app/shared/models/stock';
import { Location } from 'src/app/shared/models/location';
import { MatDialog } from '@angular/material/dialog';
import { StockDrinkDialogComponent } from './stock-drink-dialog/stock-drink-dialog.component';
import { AddStockComponent } from './add-stock/add-stock.component';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.css']
})
export class StockComponent implements OnInit {
  @Input() stockLocation: Location;
  public locationString: string = '';

  public brandLogoPath: string = "../../../../assets/images/brands/";
  public referenceImagePath: string = "../../../../assets/images/references/";

  public allStock: Stock[] = [];
  public allBrands: Brand[] = [];
  public allReferences: Reference[] = [];

  public onLoad: boolean = false;

  constructor(
    private _apiService: ApiService,
    private _dialog: MatDialog,
    private _functionsService: FunctionsService,
    private _navigationService: NavigationService,
  ) { }

  ngOnInit(): void {
    this.refreshStock();
  }

  private refreshStock(): void{
    this.onLoad = true;
    this._apiService.getStockByLocation(this.stockLocation).subscribe((allStockApi: Stock[])=>{
      this.allStock = [];
      allStockApi.forEach((someStock: Stock)=>{
        this.allStock.push(someStock);
      });
      //get all ref in stock
      this._apiService.getAllReferences().subscribe((allReferencesFromApi: Reference[])=>{
        this.allReferences = [];
        allReferencesFromApi.forEach((someRef: Reference)=>{
          this.allStock.forEach((someStock: Stock) => {
            if(someStock.reference == someRef.id && !this.allReferences.includes(someRef)){
              this.allReferences.push(someRef);
            }
          });
        });
        //get all brand in ref
        this._apiService.getAllBrands().subscribe((allBrandsFromApi: Brand[])=>{
          this.allBrands = [];
          allBrandsFromApi.forEach((someBrand: Brand)=>{
            this.allReferences.forEach((someRef: Reference)=>{
              if(someRef.brand == someBrand.id && !this.allBrands.includes(someBrand)){
                this.allBrands.push(someBrand);
              }
            });
          });
          this.onLoad = false;
        },
        ()=>{this.onLoad = false});
      },
      ()=>{this.onLoad = false});
    },
    ()=>{this.onLoad = false;});
  }

  public displayBrand(brand: Brand): boolean{
    let displayBrand: boolean = false;
    this.allStock.forEach((someStock: Stock)=>{
      if(someStock.location == this.stockLocation.id){
        this.allReferences.forEach((someRef: Reference)=>{
          if(this.getStockQuantite(someRef) > 0){
            if(someRef.brand == brand.id){
              displayBrand = true;
            }
          }
        });
      }
    });
    return displayBrand;
  }

  public displayStock(reference: Reference): boolean{
    let displayStock: boolean = false;
    this.allStock.forEach((someStock: Stock)=>{
      if(someStock.location == this.stockLocation.id && reference.id == someStock.reference){
        if(someStock.quantite > 0){
          displayStock = true;
        }else{
          displayStock = false;
        }
      }
    });
    return displayStock;
  }

  public getStockQuantite(reference: Reference): number{
    let stockQuantite: number = 0;
    this.allStock.forEach((someStock: Stock)=>{
      if(someStock.location == this.stockLocation.id && reference.id == someStock.reference){
        stockQuantite = someStock.quantite;
      }
    });
    return stockQuantite;
  }

  public btn_drink_click(reference: Reference, location: Location, brand: Brand, stock: Stock): void{
    if(stock.quantite > 0){
      const dialogRef = this._dialog.open(StockDrinkDialogComponent,{
        width: '250px',
        data: {reference: reference, location: location, brand: brand, stock: stock}
      });

      dialogRef.afterClosed().subscribe((result: boolean)=>{
        if(result){
          this.refreshStock();
          this._functionsService.openSuccessSnackBar("Santé !");
        }
      });
    }else{
      this._functionsService.openErrorSnackBar("Cherche pas ! Y'en n'a plus...")
    }
  }

  public btn_addStock_click(): void{
    const dialogRef = this._dialog.open(AddStockComponent, {
      width: '500px',
      data: {location: this.stockLocation, type: 'fill'}
    });

    dialogRef.afterClosed().subscribe((result: boolean)=>{
      if(result){
        this.refreshStock();
      }
    });
  }

  public btn_transferStock_click(): void{
    const dialogRef = this._dialog.open(AddStockComponent, {
      width: '500px',
      data: {location: this.stockLocation, type: 'transfer'}
    });

    dialogRef.afterClosed().subscribe((result: boolean)=>{
      if(result){
        this.refreshStock();
      }
    });
  }

  public btn_backToSelect_click(): void{
    this._navigationService.backToSelect(this.stockLocation.sublocation);
  }

}
