import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { AddReferenceComponent } from './add-reference/add-reference.component';
import { Reference } from 'src/app/shared/models/reference';
import { Brand } from 'src/app/shared/models/brand';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.css']
})
export class ReferencesComponent implements OnInit {
  public allReferences: Reference[];
  public allBrands: Brand[];
  public onLoad: boolean = false;

  constructor(
    private _dialog: MatDialog,
    private _apiService: ApiService,
  ) { }

  ngOnInit(): void {
    this.refreshAllReferences();
  }

  public refreshAllReferences(): void{
    this.onLoad = true;
    this._apiService.getAllBrands().subscribe((allBrandsFromApi: Brand[])=>{
      this.allBrands = allBrandsFromApi;
      this._apiService.getAllReferences().subscribe((allRefFromApi: Reference[])=>{
        this.allReferences = allRefFromApi;
        this.onLoad = false;
      });
    },
    ()=>{this.onLoad = false;},
    ()=>{this.onLoad = false;});
  }

  public btn_addRef_click(): void{
    this.openDialog('add');
  }

  private openDialog(type: string, ref: Reference = null): void{
    const dialogRef = this._dialog.open(AddReferenceComponent, {
      width: '500px',
      data: {type: type, ref: ref}
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result){
        this.refreshAllReferences();
      }
    });
  }

}
