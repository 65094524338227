<h1 mat-dialog-title>{{ dialogTitle }}</h1>
<mat-dialog-content>
    <div *ngIf="onLoad" class="py-3 d-flex justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!onLoad">
        <form class="d-flex flex-column">
            <div class="d-flex justify-content-center">
                <mat-form-field>
                    <mat-label>Marque de la référence</mat-label>
                    <mat-select [(value)]="selectedBrand">
                        <mat-option *ngFor="let someBrand of allBrands" [value]="someBrand">{{ someBrand.libelle }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="d-flex justify-content-center">
                <mat-form-field>
                    <mat-label>Nom de la référence</mat-label>
                    <input matInput type="text" [formControl]="input_refName_value"/>
                </mat-form-field>
            </div>
            <div class="d-flex justify-content-center">
                <mat-form-field>
                    <mat-label>Contenance</mat-label>
                    <input matInput type="number" [formControl]="input_refContenance_value"/>
                    <span matSuffix>cl</span>
                </mat-form-field>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-button mat-dialog-close>Annuler</button>
    <button mat-button (click)="btn_valid_click()">Valider</button>
</mat-dialog-actions>
