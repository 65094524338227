import { Brand } from './brand';

export class Reference {
    private _id: number;
    private _libelle: string;
    private _contenance: number;
    private _brand: number;
    private _image: string;

    constructor(){
        this.id = null;
        this.libelle = null;
        this.contenance = null;
        this.brand = null;
        this.image = null;
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter libelle
     * @return {string}
     */
	public get libelle(): string {
		return this._libelle;
	}

    /**
     * Getter contenance
     * @return {number}
     */
	public get contenance(): number {
		return this._contenance;
	}

    /**
     * Getter brand
     * @return {number}
     */
	public get brand(): number {
		return this._brand;
	}

    /**
     * Getter image
     * @return {string}
     */
	public get image(): string {
		return this._image;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter libelle
     * @param {string} value
     */
	public set libelle(value: string) {
		this._libelle = value;
	}

    /**
     * Setter contenance
     * @param {number} value
     */
	public set contenance(value: number) {
		this._contenance = value;
	}

    /**
     * Setter brand
     * @param {number} value
     */
	public set brand(value: number) {
		this._brand = value;
	}

    /**
     * Setter image
     * @param {string} value
     */
	public set image(value: string) {
		this._image = value;
	}
    
}
